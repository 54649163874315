// Custom styles for this template

.card, .social-box {
  border-top-width: 0;
  border-bottom-width: 2 * $card-border-width;
}

[class*="card-outline-"] {
  border-top-width: 1px !important;
}

[class*="card-accent-"] {
  border-top-width: 2px !important;
}

.aside-menu {
  .nav-tabs .nav-link,
  .callout {
    border-radius: 0;
  }
}

// Here you can add other styles

.cursor-pointer {
  cursor:pointer;
}

/* Structure */
.example-container {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  padding-bottom: 20px;
}

.example-header {
  min-height: 64px;
  display: flex;
  align-items: baseline;
  padding: 8px 0px 0;
  font-size: 20px;
  justify-content: space-between;
}

.mat-form-field {
  font-size: 14px;
  flex-grow: 1;
  margin-left: 32px;
}

.mat-table {
  overflow: auto;
}

.mat-header-cell
{
  color: #000000;
  font-weight: bold;
  font-size: 13px;
}

.mat-column-id
{
  max-width: 100px;
}

.mat-column-active
{
  max-width: 100px;
}

//set default height for ngx-quill rich text editor
.ql-container{height: 200px;}

//hide table column util class
.hidden-column {
  display: none;
}

.cdk-overlay-container {
  z-index: 1022 !important;
}