// core overrides

$border-color:                        $gray-200;

// Navbar

$navbar-height:                       50px;
$navbar-bg:                           #E30613;

$navbar-bg-en:                        theme-color("primary");
$navbar-bg-de:                          #222222;
$navbar-bg-ro:                          #fba906;
$navbar-bg-cz:                          #009999;
$navbar-bg-fr:                          #0160ad;
$navbar-bg-it:                          #ff0090;
$navbar-bg-es:                          #009900;
$navbar-bg-ru:                         #92508d;

$navbar-border:                       0;
$navbar-brand-width:                  220px;


$navbar-brand-bg:                     darken($navbar-bg,10%);
$navbar-brand-bg-en:                  darken(theme-color("primary"),10%);
$navbar-brand-bg-de:                  darken($navbar-bg-de,10%);
$navbar-brand-bg-ro:                  darken($navbar-bg-ro,10%);
$navbar-brand-bg-cz:                  darken($navbar-bg-cz,10%);
$navbar-brand-bg-fr:                  darken($navbar-bg-fr,10%);
$navbar-brand-bg-it:                  darken($navbar-bg-it,10%);
$navbar-brand-bg-es:                  darken($navbar-bg-es,10%);
$navbar-brand-bg-ru:                 darken($navbar-bg-ru,10%);


$navbar-brand-logo-size:              90px auto;
$navbar-brand-border:                 0;

$navbar-color:                        #fff;
$navbar-hover-color:                  darken($navbar-color, 10%);
$navbar-active-color:                 darken($navbar-color, 20%);
$navbar-disabled-color:               lighter($navbar-color, 20%);

// Sidebar
$sidebar-bg:                          $gray-800;
$sidebar-width:                       220px;
$mobile-sidebar-width:                220px;

// Sidebar Navigation

$sidebar-nav-title-padding-y:         .95rem;
$sidebar-nav-title-color:             $text-muted;

$sidebar-nav-link-hover-bg:           darken($sidebar-bg, 10%);

$sidebar-nav-link-active-bg:          #E30613;
$sidebar-nav-link-active-icon-color:  #fff;

$sidebar-nav-dropdown-bg:             darken($sidebar-bg, 15%);

// Breadcrumb

$breadcrumb-margin:                   0;
$breadcrumb-borders:                  0;

// Footer

$footer-bg:                           $gray-800;
$footer-color:                        #fff;
